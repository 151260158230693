import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Slider from "react-slick";
import Image from 'react-bootstrap/Image';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navigation from '../components/Navigation';
import Banner from '../components/Banner';
import Modal from '../components/Modal';

import engIcon from '../assets/images/icons/engineering.png';
import resultIcon from '../assets/images/icons/trophy.png';
import medicalIcon from '../assets/images/icons/doctor.png';
import foundationIcon from '../assets/images/icons/left-up.png';

import almuni1 from '../assets/images/Bhargavi_Jiddewar.jpg';
import almuni2 from '../assets/images/Devansh_Gattani.png';
import almuni3 from '../assets/images/Pratyush_Agrawal.png';
import almuni4 from '../assets/images/Tanush_Badonia.jpg';
import quoteIcon from '../assets/images/icons/quote.png';
import programImg from '../assets/images/thumbnail.jpeg';
import cpaLogo from '../assets/images/cpaLogo.png';
import cpaCalender from '../assets/images/cpaCalender.jpg';

const Home = props => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    className: "almuni_slider",
    autoplay: true,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [      
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  var testSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    pauseOnHover: true,
    adaptiveHeight: true,
    className: "test_slider",
    responsive: [      
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const [count, setCount] = useState(0);
  const counters = document.querySelectorAll('.counter');
  const speed = 500; // The lower the slower

counters.forEach(counter => {
	const updateCount = () => {
		const target = +counter.getAttribute('data-target');
		const count = +counter.innerText;

		// Lower inc to slow and higher to slow
		const inc = target / speed;

		// console.log(inc);
		// console.log(count);

		// Check if target is reached
		if (count < target) {
			// Add inc to count and output in counter
			counter.innerText = Math.round(count + inc);
			// Call function every ms
			setTimeout(updateCount, 1);
		} else {
			counter.innerText = target;
		}
	};

	updateCount(setCount);
});

useEffect(() => {
  setCount();
}, [count]);

const [isOpen, setIsOpen] = useState(false);
const [modalTitle, setModalTitle] = useState('');
const [modalBody, setModalBody] = useState('');
const [modalSize, setModalSize] = useState('lg');

const communityBody = () => {
  return(
    <div>
      <ul className='check_list'>
        <li>Learning Techniques</li>
        <li>Command Capsule</li>
        <li>Online App</li>
        <li>Rank Booster</li>
        <li>Doubt Clearing</li>
        <li>Discipline</li>
      </ul>
    </div>
  );
};

const communityHeader = () => {
  return(
    <div>
      <h4>Our Unique Teaching Methods</h4>
    </div>
  );
};

const openModal = (title, body, size) => {
  setModalTitle(title);
  setModalBody(body);
  setModalSize(size);
  setIsOpen(true);
};

const closeModal = () => {
  setIsOpen(false);
  setModalTitle('');
  setModalBody('');
};

  return (
    <>
      <Helmet>
        <title>ICAD Study Center - IIT-JEE / NEET coaching in Nagpur, JEE MAINS, JEE ADVANCE</title>
        <meta name="description" content="Welcome to my website. Explore various topics and resources." />
        <meta name="keywords" content="JEE coaching, NEET preparation, foundation program, best IIT coaching Nagpur, personalized education" />
      </Helmet>
   
      <div className='header_wrapper'>        
        <Navigation/>
        <Banner/>
      </div>
      <section className='cpa_strip'>
          <div className='cpa_strip_content container'>
            <div className='text-center first'>
                <div style={{marginRight:'30px'}}>
                  <Image src={cpaLogo} alt='cpa logo' fluid></Image>
                </div>      
            </div>
            <div className='text-center second'>
              <div>
                  <h1 className='title'>Unlock Your Future Potential</h1>                   
                  <h3 className='mb-3' style={{color:'#000', fontFamily:'robotobold'}}>Earn Scholarships up to <span style={{color:'#fff',fontSize:'1.5em'}}>90</span>%</h3> 
                  {/* <div className='ribbon mb-3'>
                    <h4 className='date_strip'>Apply by 15th December 2024</h4>
                  </div>                   */}
                </div>   
                <div>
                <Button className='theme_btn' href='https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true'>Apply Now</Button>
              </div>              
            </div> 
            <div className='text-right third'>
              <Image src={cpaCalender} alt='calender image' fluid></Image>              
            </div>            
          </div>
          <div className='cpa_strip_content mob container'>
            <div className='content d-flex'>
              <div className='d-flex'>
                <div className='text-center first' style={{marginRight:'15px'}}>
                    <div>
                      <div>
                        <Image src={cpaLogo} alt='cpa logo' style={{height:'70px'}} fluid></Image>
                      </div>
                    </div>      
                </div>
                <div style={{width:'30%',alignSelf:'center'}}>
                  <Image src={cpaCalender} alt='calender image' fluid></Image>
                </div>
                </div>
              <div className='text-center d-flex second'>
                <div style={{marginTop:'10px'}}>
                  <h1 className='subtitle' style={{color:'#fff'}}>Unlock Your Future Potential</h1>                   
                  <h3 className='mb-3' style={{color:'#000', fontFamily:'robotobold'}}>Earn Scholarships up to <span style={{color:'#fff',fontSize:'1.4em'}}>90</span>%</h3> 
                  {/* <div className='ribbon mb-3'>
                    <h4 className='date_strip'>Apply by 15th December 2024</h4>
                  </div>                   */}
                  <div>
                  <Button className='theme_btn' href='https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true'>Apply Now</Button>
                  </div>
                </div>                
              </div>              
            </div> 
          </div>
      </section>

      {/* intro */}
      <section>
        <Container className='mt-50' data-aos="fade-up" data-aos-duration="3000">
          <h1 className='title text-center'>Welcome to ICAD – Your Gateway to JEE & NEET Success</h1>
          <div className='text-center mb-5'>                
                <p>Welcome to ICAD, where we simplify the journey to success in JEE, NEET, and foundational 
                academic excellence. Our experienced mentors, innovative teaching methods, and 
                personalized attention have helped thousands of students achieve their dreams. Explore our 
                programs and start your success story today. </p>
              </div>
          <Row>
            <Col lg={12} sm={12}>              
              <div>
                <h2 className='subtitle'>Why Choose ICAD? </h2>
                <p>At ICAD, we believe every student deserves personalized guidance. Here’s why we’re the 
                right choice for your academic journey: </p>
                <ul className='check_list'>
                  <li>Innovative Learning Resources: Our Command Capsules and Digital Classes offer concise 
                  theory, real-life applications, and exam-oriented numericals.</li>
                  <li>Personalized Attention: Every student gets one-on-one mentorship, regular tests, and 
                  assessments to identify strengths and weaknesses. </li>
                  <li>Proven Track Record: With over two decades of experience and thousands of successful 
                  students, ICAD stands as a trusted institution.</li>
                </ul>
            </div>
            </Col>
            <Col lg={12} sm={12}>
                <Row className='mt-4'>
                  <Col sm={6} className='mb-15'>
                    <Card className='theme_card course_card'>
                      <Card.Body>
                        <div className='icon_div mb-15'><Image src={engIcon} fluid></Image></div>
                        <h4>Engineering</h4>
                        <p>ICAD helps students prepare for JEE exams to get into the best engineering colleges like IITs and NITs. With expert teachers, easy-to-understand materials, and regular practice, we guide them to achieve their goals.</p>
                        <a href='/engineering'>View Details-></a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6} className='mb-15'>
                    <Card className='theme_card course_card'>
                      <Card.Body>
                        <div className='icon_div mb-15'><Image src={medicalIcon} fluid></Image></div>
                        <h4>Medical</h4>
                        <p>ICAD NEET coaching helps students get into top medical colleges like AIIMS and GMC. With experienced teachers, simple study materials, and regular tests, we make sure they are ready to succeed.</p>
                        <a href='/medical'>View Details-></a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6} className='mb-15'>
                    <Card className='theme_card course_card'>
                      <Card.Body>
                        <div className='icon_div mb-15'><Image src={foundationIcon} fluid></Image></div>
                        <h4>Foundation</h4>
                        <p>A course for students transitioning to higher education, focusing on study skills, critical thinking, and subject-specific knowledge.</p>
                        <a href='/foundation'>View Details-></a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className='theme_card course_card'>
                      <Card.Body>
                        <div className='icon_div mb-15'><Image src={resultIcon} fluid></Image></div>
                        <h4>Results</h4>
                        <p>Outcomes related to the performance of individuals, often measured against specific criteria or benchmarks.</p>
                        <a href='/results'>View Details-></a>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
            </Col>
          </Row>        
      </Container>
      </section>

    {/* counters */}
    <section className='counters counter_sec mt-50' data-aos="fade-up" data-aos-duration="3000">
        <Container>
            <div className='main_div'>
                <Row>
                    <Col xl={3} lg={6} md={6} sm={6}>
                        <div className='counter_item'>
                            <h1 className='counter counter_no first' data-target="774">0</h1>
                            <p className='counter_text'>Selections in JEE-Advanced</p>
                        </div>
                      
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6}>
                        <div className='counter_item'>
                            <h1 className='counter counter_no' data-target="5467">0</h1>
                            <p className='counter_text'>Selections in JEE-Main</p>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6}>
                        <div className='counter_item'>
                            <h1 className='counter counter_no' data-target="6">6</h1>
                            <p className='counter_text'>International Medals in Olympiad</p>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6}>
                        <div className='counter_item'>
                            <h1 className='counter counter_no' data-target="568">0</h1>
                            <p className='counter_text'>Selections in NEET-UG</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </section>

    {/* our success story */}
    {/* <section className='custom_sec bg_light_gray' data-aos="fade-up" data-aos-duration="3000">
      <Container>
        <Row>
          <Col sm={12} className='mb-2'>
            <h2>Our Success Stories</h2>
            <p>Hear from our top performers and see how ICAD has helped them crack the toughest competitive exams:</p>
          </Col>
          <Col sm={12}>
            <Slider {...testSettings}>
              <Card className='theme_card testimonial_grid'>
                <Card.Body>
                  <div className='thumbnail'>
                    <Image src={almuni1} className='thumb_img'></Image>
                    <span className='quote_icon'>
                      <Image src={quoteIcon} fluid></Image>
                    </span>
                  </div>
                  <div className='content'>
                    <p>Lorem ipsum dolor amet consec tur elit adicing sed do usmod zx tempor enim minim veniam quis nostrud exer citation.</p>
                    <h5>Aswini Saxena</h5>
                  </div>
                </Card.Body>
              </Card>
              <Card className='theme_card testimonial_grid'>
                <Card.Body>
                  <div className='thumbnail'>
                    <Image src={almuni1} className='thumb_img'></Image>
                    <span className='quote_icon'>
                      <Image src={quoteIcon} fluid></Image>
                    </span>
                  </div>
                  <div className='content'>
                    <p>Lorem ipsum dolor amet consec tur elit adicing sed do usmod zx tempor enim minim veniam quis nostrud exer citation.</p>
                    <h5>Aswini Saxena</h5>
                  </div>
                </Card.Body>
              </Card>
              <Card className='theme_card testimonial_grid'>
                <Card.Body>
                  <div className='thumbnail'>
                    <Image src={almuni1} className='thumb_img'></Image>
                    <span className='quote_icon'>
                      <Image src={quoteIcon} fluid></Image>
                    </span>
                  </div>
                  <div className='content'>
                    <p>Lorem ipsum dolor amet consec tur elit adicing sed do usmod zx tempor enim minim veniam quis nostrud exer citation.</p>
                    <h5>Amar Saxena</h5>
                  </div>
                </Card.Body>
              </Card>
              <Card className='theme_card testimonial_grid'>
                <Card.Body>
                  <div className='thumbnail'>
                    <Image src={almuni1} className='thumb_img'></Image>
                    <span className='quote_icon'>
                      <Image src={quoteIcon} fluid></Image>
                    </span>
                  </div>
                  <div className='content'>
                    <p>Lorem ipsum dolor amet consec tur elit adicing sed do usmod zx tempor enim minim veniam quis nostrud exer citation.</p>
                    <h5>Amar Saxena</h5>
                  </div>
                </Card.Body>
              </Card>
            </Slider>
          </Col>
        </Row>
        
      </Container>
    </section> */}

    {/* explore our programs */}
    <section className="mt-50 custom_sec" data-aos="fade-up" data-aos-duration="3000">
      <Container>
        <Row>
          <Col md={6} sm={12} className='mb-small'>
            <Image src={programImg} atl="explore our program image" style={{borderRadius:'20px'}} fluid></Image>
          </Col>
          <Col md={6} sm={12}>
            <h2>Explore Our Programs</h2>
            <p>Whether you're aiming for JEE, NEET, or building a strong academic foundation, ICAD offers 
            programs designed meticulously to meet your needs:</p>
            <ul className='check_list'>
              <li>JEE Coaching: Intensive preparation with concept clarity, hint methodology, and advanced 
              problem-solving.</li>
              <li>NEET Coaching: Comprehensive biology, physics, and chemistry NCERT based coaching to 
              master medical entrance exams.</li>
              <li>Foundation Programs: For grades 8, 9, and 10 to build a strong base in not only in School 
              curriculum but also in advanced maths and science for competitive exams.</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>

    {/* join our community */}
    <section  className="mt-50 custom_sec bg_light_gray" data-aos="fade-up" data-aos-duration="3000">
      <Container className='text-center'>
        <h2>Join Our Community</h2>
        <p>Your dream career begins here. Join the ICAD community and take the first step toward 
        academic excellence.</p>
        <div className='btn_group'>
          {/* <Button className="theme_btn mb-small">Get Started with a Free Demo Class</Button> */}
          <Button className="theme_btn" onClick={() => openModal(communityHeader, communityBody, 'md')}>Learn More About Our Unique Teaching Methods</Button>
        </div>
      </Container>
    </section>

    {/* our alumni */}
    <section  data-aos="fade-up" data-aos-duration="3000">
      <Container className='mt-50'>
        <h2 className='title'>Our Alumni</h2>
          <Slider {...settings}>
              <div className='card'>
                <div className='card-img'><img src={almuni2} alt="Card image" /></div>
                <div className='card-body'>
                  <h4 className='card-title'>Devansh Gattani</h4>
                  <h6 className='card-subtitle'>JEE Advanced</h6>
                  <p class="card-text">
                  I am delighted to share my success story with ICAD. Achieving AIR 87 in JEE Advanced 2024 and 99.998 percentile (AIR 82) in JEE Mains has been possible due to ICAD's exceptional support.<br/>
                  The <b>Command Capsule</b> study material, <b>special learning techniques, and AI-assisted test series</b> were instrumental in my preparation.Their innovative methods, personalized feedback, and robust academic systems provided comprehensive coverage and detailed performance analysis, keeping me motivated and on track.<br/>
                  I am immensely grateful to ICAD for their unwavering support and guidance. For any JEE aspirant, ICAD is the best choice for achieving academic excellence.
                  </p>
                </div>
              </div>
              <div className='card'>
                <div className='card-img'><img src={almuni1} alt="Card image" /></div>
                <div className='card-body'>
                  <h4 className='card-title'>Bhargavi</h4>
                  <h6 className='card-subtitle'>NEET Repeater</h6>
                  <p class="card-text">
                    Working with ICAD was inevitable. This whole experience not only improved my academic performance but also made me more confident in handling whatever comes next. I learned the most important technique, ‘meditation,’ which not only resolved all my anxiety and panic issues but also made me mentally stronger.
                    <br/>Akshay Sir played an undeniable role in my journey to reach my full potential. He actively listened to every problem I had and provided solutions. All the faculty members were so supportive and helpful that I never faced any issues with doubts. My doubts were always resolved in a timely manner.<br/>
                    The Command Capsule was also the best book, a collection of various mixed questions that gave me the confidence to tackle any question. The teaching style, testing patterns, and subsequent analysis techniques helped me reach my goal. The encouraging and non-judgmental environment always motivated me to achieve unexpected results.<br/>
                    Thank you, ICAD team, for helping me get one step closer to my dreams.
                  </p>
                </div>
              </div>
              <div className='card'>
                <div className='card-img'><img src={almuni4} alt="Card image" /></div>
                <div className='card-body'>
                  <h4 className='card-title'>Tanush Badonia</h4>
                  <h6 className='card-subtitle'></h6>
                  <p class="card-text">
                    I am immensely grateful to ICAD for their exceptional guidance and support, which enabled me to achieve a perfect 100 percentile in Physics in both sessions of JEE Main (2024). ICAD's expert faculty, comprehensive curriculum, and personalized attention were instrumental in mastering complex concepts and honing my problem-solving skills. The rigorous mock tests and practice sessions closely mirrored the actual exam, greatly contributing to my success. Thank you, ICAD, for your invaluable support.
                  </p>
                </div>
              </div>
              <div className='card'>
              <div className='card-img'><img src={almuni3} alt="Card image" /></div>
                <div className='card-body'>
                  <h4 className='card-title'>Pratyush Agrawal</h4>
                  <h6 className='card-subtitle'></h6>
                  <p class="card-text">
                    I express my gratitude to ICAD for their invaluable support and guidance throughout my academic journey. With their assistance, I achieved a commendable 99% in the CBSE board exams. ICAD's comprehensive curriculum, dedicated faculty, and personalized attention were instrumental in my success. Their tests series and Board moderators sessions were particularly helpful in preparing me for the exam. Thank you, ICAD, for your unwavering commitment to my education.
                  </p>
                </div>
              </div>
          </Slider>
      </Container> 
    </section>

    <Modal isOpen={isOpen} onClose={closeModal} title={modalTitle} body={modalBody} size={modalSize} />
    </>
  );
};

export default Home;