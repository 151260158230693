import React from 'react';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import facebookIcon from '../assets/images/icons/facebook.svg';
import twitIcon from '../assets/images/icons/twitter.svg';
import blogIcon from '../assets/images/icons/blog.svg';
import ytubeIcon from '../assets/images/icons/youtube.svg';
import instaIcon from '../assets/images/icons/instagram.svg';

const TopBar = props => {
  return (
    <div className="top_nav">
      <Container fluid="lg">
        <div className='d-flex'>
          <div className='top_left'>
              <ul>
                  <li><b>Call Us:</b> 7074 34 7074</li>
                  <li><b>Mail Us:</b> contact@icadiit.com</li>
              </ul>
          </div>
          <div className='top_right'>
            <ul>
            <li><a href='https://www.facebook.com/jee.icad/' target="_blank"><img src={facebookIcon} className='svg_img'/></a></li>
                        <li><a href='https://twitter.com/#!/ICADSL' target="_blank"><img src={twitIcon} className='svg_img'/></a></li>
                        <li><a href='https://www.youtube.com/channel/UCm2p1O0DA9Xgv18pUe7Juew?feature=watch' target="_blank"><img src={ytubeIcon} className='svg_img'/></a></li>
                        <li><a href='http://icadsl.blogspot.com/' target="_blank"><img src={blogIcon} className='svg_img'/></a></li>
                        <li><a href='https://www.instagram.com/icadsl/?utm_source=ig_profile_share&amp;igshid=1m6rhkv66v0yd' target="_blank"><img src={instaIcon} className='svg_img'/></a></li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TopBar;